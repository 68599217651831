import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { exercise_log_mins, exercise_log_rep, exercise_log_sets,
  exercise_log_exertion_scale, exercise_log_exertion_scale_es, frm_yes_no} from '../../variables/participant_contract.js'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ShareIcon from '@material-ui/icons/Share'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import { ControlSection } from '../../shared/control_section'
import { TextFieldLine } from '../../shared/text_field_line'
import { SelectLine } from '../../shared/select_line'
import { FormSection } from '../../shared/form_section'
import { Line } from '../../shared/line'
import ShareExerciseLog from './share_exercise_log'
import FaceIcon from '@material-ui/icons/Face'
import SampleImage from '../../../images/sample.png'
import SampleImageES from '../../../images/sample_es.png'
import Cookies from 'js-cookie'

class ExerciseLog extends Component {
  constructor() {
    super()
    this.quill = require('react-quill')
    this.state = {
        completed: false,
        edit: false,
        status_submit: true,
        message: '',
        flexibility_mins: 0,
        flexibility_mins_values: [],
        aerobic_activity: '',
        aerobic_activity_values: [],
        aerobic_activity_other: '',
        aerobic_mins: 0,
        aerobic_intensity: 0,
        aerobic_intensity_other: '',
        miles: 0,
        strength_upper_body: 0,
        strength_upper_body_reps: 0,
        strength_upper_body_sets: 0,
        strength_upper_body_band: 0,
        strength_lower_body: 0,
        strength_lower_body_reps: 0,
        strength_lower_body_sets: 0,
        strength_lower_body_band: 0,
        exertion_scale: -1,
        exercise_log_intensity: [],
        shared_exercise_log: [],
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    var pid = this.props.authReducer.uid
    var get_date = new Date(this.props.match.params.year, this.props.match.params.month, this.props.match.params.day)
    axios({
      method: 'post',
      url: connectNode + 'participants/activity/get_exercise_log',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: pid,
        date_log: ("0" + get_date.getMonth()).slice(-2) + '/' + ("0" + get_date.getDate()).slice(-2) + '/' + this.props.match.params.year,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].uid === pid) {
        this.setState({
            flexibility_mins: result['data'].flexibility_mins,
            aerobic_activity: result['data'].aerobic_activity,
            aerobic_activity_other: result['data'].aerobic_activity_other,
            aerobic_mins: result['data'].aerobic_mins,
            aerobic_intensity: result['data'].aerobic_intensity,
            aerobic_intensity_other: result['data'].aerobic_intensity_other,
            miles: result['data'].miles,
            strength_upper_body: result['data'].strength_upper_body,
            strength_upper_body_reps: result['data'].strength_upper_body_reps,
            strength_upper_body_sets: result['data'].strength_upper_body_sets,
            strength_upper_body_band: result['data'].strength_upper_body_band,
            strength_lower_body: result['data'].strength_lower_body,
            strength_lower_body_reps: result['data'].strength_lower_body_reps,
            strength_lower_body_sets: result['data'].strength_lower_body_sets,
            strength_lower_body_band: result['data'].strength_lower_body_band,
            exertion_scale: result['data'].exertion_scale,
            completed: true,
            exercise_log_intensity: result['data'].exercise_log_intensity,
            aerobic_activity_values: result['data'].aerobic_activity_values,
            flexibility_mins_values: result['data'].mins_exercise,
            shared_exercise_log: result['data'].shared_to,
          })
      } else {
        this.setState({
          exercise_log_intensity: result['data'].exercise_log_intensity,
          aerobic_activity_values: result['data'].aerobic_activity_values,
          flexibility_mins_values: result['data'].mins_exercise,
          shared_exercise_log: result['data'].shared_to,
        })
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value,
    })
  }

  handleEdit = (e) => {
    this.setState({
      edit: true,
    })
    window.scrollTo(0, 0)
  }

  validateAllField = () => {
    var chk = true
    var chk_list = true
    var txt_warning = ''

    if(this.state.exertion_scale === -1) {
      txt_warning = 'Please select Perceived Rate of Exertion Scale'
    }

    if(this.state.miles < 0 || this.state.miles > 200) {
      txt_warning = 'Please fill correct number for Aerobic / Miles'
    }

    if(this.state.aerobic_mins < 0 || this.state.aerobic_mins > 200) {
      txt_warning = 'Please fill correct number for Aerobic / Minutes'
    }

    if(this.state.flexibility_mins < 0 || this.state.flexibility_mins > 200) {
      txt_warning = 'Please fill correct number for Stretching / Flexibility'
    }

    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var get_validate_msg = this.validateAllField()
    var get_date = new Date(this.props.match.params.year, this.props.match.params.month, this.props.match.params.day)
    //console.log(get_validate_msg)
    if(get_validate_msg == '') {
      var pid = this.props.authReducer.uid
      axios({
        method: 'post',
        url: connectNode + 'participants/activity/add_exercise_log',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.authReducer.uid,
          user: this.state,
          date_log: ("0" + get_date.getMonth()).slice(-2) + '/' + ("0" + get_date.getDate()).slice(-2) + '/' + this.props.match.params.year,
          day: this.props.match.params.day,
          month: this.props.match.params.month,
          year: this.props.match.params.year,
        }
      })
      .then((result) => {
        var message = ''
        if(result['data'] == pid) {
          this.setState({
            message: 'Thank you for submitting your exercise log!',
            status_submit: true,
            completed: true,
          })
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: get_validate_msg,
        status_submit: false,
      })
      window.scrollTo(0, 0)
    }
  }

  handleSave = (e) => {
    e.preventDefault()
    var get_validate_msg = this.validateAllField()
    var get_date = new Date(this.props.match.params.year, this.props.match.params.month, this.props.match.params.day)
    //console.log(get_validate_msg)
    if(get_validate_msg == '') {
      var pid = this.props.authReducer.uid
      axios({
        method: 'post',
        url: connectNode + 'participants/activity/save_exercise_log',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.authReducer.uid,
          user: this.state,
          date_log: ("0" + get_date.getMonth()).slice(-2) + '/' + ("0" + get_date.getDate()).slice(-2) + '/' + this.props.match.params.year,
          day: this.props.match.params.day,
          month: this.props.match.params.month,
          year: this.props.match.params.year,
        }
      })
      .then((result) => {
        var message = ''
        if(result['data'] == pid) {
          this.setState({
            message: 'Edit exercise log successfully!',
            status_submit: true,
            completed: true,
          })
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: get_validate_msg,
        status_submit: false,
      })
      window.scrollTo(0, 0)
    }
  }

  handleUpdateStatus = () => {
    document.getElementById('warning_box').style.display = 'block'
  }

  closeWarningBox = (e) => {
    document.getElementById('warning_box').style.display = 'none'
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      if(obj.vid == searchValue) {
        label = obj.name
      }
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  findLabelByValue2 = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      if(obj.value == searchValue) {
        label = obj.label
      }
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    const lang = Cookies.get('lang_')
    const isDisabled = this.state.completed;

    var aerobicActivityMap = (lang == 'es') ? this.state.aerobic_activity_values.map(each => ({ id: each.vid, name: each.name_es })) : this.state.aerobic_activity_values.map(each => ({ id: each.vid, name: each.name }))
    //values.map(each => ({ id: each.vid, name: each.name }));
    const aerobicIntensityMap = this.state.exercise_log_intensity.map(each => ({ id: each.vid, name: each.name }));
    const minutesMap = Array.from(Array(101).keys()).map(each => ({ id: each, name: each })); // this.state.flexibility_mins_values.map(each => ({ id: each.vid, name: each.name }));
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const weekday_es = ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"];
    const repsMap = Array.from(Array(31).keys()).map(each => ({ id: each, name: each }));
    const setsMap = Array.from(Array(31).keys()).map(each => ({ id: each, name: each }));
    repsMap.shift();
    setsMap.shift();

    var get_date = new Date(this.props.match.params.month+'/'+this.props.match.params.day+'/'+this.props.match.params.year)
    const choices = lang == 'es' ? exercise_log_exertion_scale_es : exercise_log_exertion_scale
    return (
    <div className="assm_page1">
        <div className="row headerPage1">
          <div className="small-12 medium-9 columns">
          {(lang=='en' && 'Exercise Log') || (lang=='es' && 'Registro de Ejercicio')}:&nbsp;
          {(lang=='en' && weekday[get_date.getDay()]) || (lang=='es' && weekday_es[get_date.getDay()])}&nbsp;
          {("0"+this.props.match.params.month).slice(-2)}/{("0" + this.props.match.params.day).slice(-2)}/{this.props.match.params.year}
          </div>
          <div className="small-12 medium-3 columns text_align_right1">

              {/*<a onClick={()=>this.handleUpdateStatus()}><ShareIcon className="share_button" /></a>*/}
              <Button variant="contained" color="primary" className="margin_right_1"
               onClick={()=>this.handleUpdateStatus()} type="button">{(lang=='en' && 'Share') || (lang=='es' && 'COMPARTIR')}</Button>

          </div>
        </div>
        <div className="small-12 columns box_assm1 more_padding1 display_flex">
        <div className="small-12 columns">
          { (this.state.message !== '') &&
            <>
              <div className="row margin-bottom1">
                <div className={(!this.state.status_submit && 'small-12 columns warning_msg6')
                || (this.state.status_submit && 'small-12 columns warning_msg7')} >
                  <div className="float_left1">
                  {!this.state.status_submit && <WarningIcon />}
                  {this.state.status_submit && <CheckCircleOutlineIcon />}
                  </div>
                  <div className="txt_warning_msg1">{ this.state.message }</div>
                </div>
              </div>
              <br/>
            </>}
          </div>
          <ControlSection label={(lang=='en' && 'Stretching / Flexibility') || lang=='es' && 'Estiramientos/ Flexibilidad'}>
            <div className="row columns top_space1">
              <div className="small-6 medium-2 columns title1">
                {(lang=='en' && 'Minutes') || lang=='es' && 'Minutos'}
              </div>
              <div className="small-6 medium-10 columns">
                <TextField variant="outlined" type="number" onChange={this.handleChange}
                  value={this.state.flexibility_mins} disabled={isDisabled && !this.state.edit}
                  name="flexibility_mins" maxLength={3} />
              </div>
            </div>
          </ControlSection>

          <ControlSection label={(lang=='en' && 'Aerobic') || lang=='es' && 'Aeróbico'} withLine>
            <div className="row columns top_space1">
              <div className="small-6 medium-2 columns title1">
                {(lang=='en' && 'Activity') || lang=='es' && 'Actividad'}
              </div>
              <div className="small-6 medium-10 columns top_space1">
              <FormControl variant="outlined" className="w_style1">
                <Select value={this.state.aerobic_activity} variant="outlined"
                  label="" defaultValue="" disabled={isDisabled && !this.state.edit}
                  onChange={this.handleChange} name="aerobic_activity" >
                  { aerobicActivityMap.map(each =>
                    <MenuItem key={'a_act'+each.id}
                    value={each.id}>{each.name}</MenuItem>)}
                </Select>
              </FormControl>
              </div>
            </div>

            {this.state.aerobic_activity == 99 &&
            <div className="row columns top_space1">
              <div className="small-6 medium-2 columns title1">
                Activity Other
              </div>
              <div className="small-6 medium-10 columns top_space1">
              <TextField onChange={this.handleChange} variant="outlined" className="w_style1"
                value={this.state.aerobic_activity_other} disabled={isDisabled && !this.state.edit}
                name="aerobic_activity_other" inputProps={{ maxLength: 198 }} />
              </div>
            </div>}

            <div className="row columns top_space1">
              <div className="small-6 medium-2 columns title1">{(lang=='en' && 'Minutes') || lang=='es' && 'Minutos'}</div>
              <div className="small-6 medium-10 columns top_space1">
              <TextField variant="outlined" type="number" onChange={this.handleChange}
                value={this.state.aerobic_mins} disabled={isDisabled && !this.state.edit}
                name="aerobic_mins" inputProps={{ maxLength: 3 }} />
              </div>
            </div>

            <div className="row columns top_space1">
              <div className="small-6 medium-2 columns title1">{(lang=='en' && 'Miles') || lang=='es' && 'Millas'}</div>
              <div className="small-6 medium-10 columns top_space1">
              <TextField variant="outlined" type="number" onChange={this.handleChange}
                value={this.state.miles} disabled={isDisabled && !this.state.edit}
                name="miles" inputProps={{ maxLength: 3 }} />
              </div>
            </div>

            <div className="row columns top_space1">
              <div className="small-6 medium-2 columns title1">
                {(lang=='en' && 'Intensity') || lang=='es' && 'Intensidad'}
              </div>
              <div className="small-6 medium-10 columns top_space1">
              <FormControl variant="outlined" className="w_style1">
                <Select value={this.state.aerobic_intensity} variant="outlined"
                  label="" defaultValue="" disabled={isDisabled && !this.state.edit}
                  onChange={this.handleChange} name="aerobic_intensity" >
                  { aerobicIntensityMap.map(each =>
                    <MenuItem key={'a_act'+each.id}
                    value={each.id}>{each.name}</MenuItem>)}
                </Select>
              </FormControl>
              </div>
            </div>
          </ControlSection>

          <div className="bold space_bottom1">{(lang=='en' && 'Strength') || lang=='es' && 'Fuerza '}</div>
          <div className="row">
            <div className="small-12 medium-8 columns">
            <div className="row">
              <div className="medium-2 columns show-for-medium">&nbsp;</div>
              <div className="small-12 medium-10 columns box_assm3 float_left">
                <div className="small-12 medium-3 columns title1">{(lang=='en' && 'Upper Body') || lang=='es' && 'Parte Superior del Cuerpo'}</div>
                <div className="small-12 medium-9 columns top_space1">
                {frm_yes_no.map(each =>
                  <div className="small-6 columns float_left" key={'sub'+each.value}>
                    <input type="radio" name="strength_upper_body" value={each.value}
                    disabled={(this.state.completed && 'disabled') && (!this.state.edit && 'disabled')}
                    checked={this.state.strength_upper_body == each.value && 'checked'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span>{(each.label == 'Yes' && lang=='es' && 'Si')}{((each.label == 'No' || lang=='en') && each.label)}</span>
                  </div>)}
                </div>

                {this.state.strength_upper_body == 1 &&
                <div>
                  <div className="small-12 columns sep_line4">&nbsp;</div>
                  <div style={{marginTop: '1.3rem'}} className="small-3 medium-3 columns title1">{(lang=='en' && 'Sets') || lang=='es' && 'Series'}</div>
                  <div style={{width: '35%'}} className="small-9 medium-9 columns top_space1">
                    <SelectLine value={this.state.strength_upper_body_sets}
                          name="strength_upper_body_sets" onChange={this.handleChange}
                          menuItems={setsMap} wide noClass noHorizontalPadding
                          disabled={this.state.completed && !this.state.edit}/>
                  </div>

                  <div className="small-12 columns sep_line4">&nbsp;</div>
                  <div style={{marginTop: '1.3rem'}} className="small-3 medium-3 columns title1">{(lang=='en' && 'Reps') || lang=='es' && 'Repeticiones'}</div>
                  <div style={{width: '35%'}} className="small-9 medium-9 columns top_space1">
                    <SelectLine value={this.state.strength_upper_body_reps}
                          name="strength_upper_body_reps" onChange={this.handleChange}
                          menuItems={repsMap} wide noClass noHorizontalPadding
                          disabled={this.state.completed && !this.state.edit} />
                  </div>

                  <div className="small-12 columns sep_line4">&nbsp;</div>
                  <div className="small-12 medium-3 columns title1 top_space1">{(lang=='en' && 'Band') || lang=='es' && 'Banda'}</div>
                  <div className="small-12 medium-9 columns top_space1">
                  {frm_yes_no.map(each =>
                    <div className="small-6 columns float_left" key={'subb'+each.value}>
                      <input type="radio" name="strength_upper_body_band" value={each.value}
                      disabled={(this.state.completed && 'disabled') && (!this.state.edit && 'disabled')}
                      checked={this.state.strength_upper_body_band == each.value && 'checked'}
                      onChange={(e) => this.handleChange(e)}/>
                      <span>{(each.label == 'Yes' && lang=='es' && 'Si')}{((each.label == 'No' || lang=='en') && each.label)}</span>
                    </div>)}
                  </div>
                </div>}

              </div>
            </div>

            <div className="row top_space1">
              <div className="medium-2 columns show-for-medium">&nbsp;</div>
              <div className="small-12 medium-10 columns box_assm3 float_left">
                <div className="small-12 medium-3 columns title1">{(lang=='en' && 'Lower Body') || lang=='es' && 'Parte Inferior del Cuerpo'}</div>
                <div className="small-12 medium-9 columns top_space1">
                {frm_yes_no.map(each =>
                  <div className="small-6 columns float_left" key={'slb'+each.value}>
                    <input type="radio" name="strength_lower_body" value={each.value}
                    disabled={(this.state.completed && 'disabled') && (!this.state.edit && 'disabled')}
                    checked={this.state.strength_lower_body == each.value && 'checked'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span>{(each.label == 'Yes' && lang=='es' && 'Si')}{((each.label == 'No' || lang=='en') && each.label)}</span>
                  </div>)}
                </div>
                {this.state.strength_lower_body == 1 &&
                <div>
                  <div className="small-12 columns sep_line4">&nbsp;</div>
                  <div style={{marginTop: '1.3rem'}} className="small-3 medium-3 columns title1">{(lang=='en' && 'Sets') || lang=='es' && 'Series'}</div>
                  <div style={{width: '35%'}} className="small-9 medium-9 columns top_space1">
                    <SelectLine value={this.state.strength_lower_body_sets}
                          name="strength_lower_body_sets" onChange={this.handleChange}
                          menuItems={setsMap} wide noClass noHorizontalPadding
                          disabled={this.state.completed && !this.state.edit}/>
                  </div>
                  <div className="small-12 columns sep_line4">&nbsp;</div>
                  <div style={{marginTop: '1.3rem'}} className="small-3 medium-3 columns title1">{(lang=='en' && 'Reps') || lang=='es' && 'Repeticiones'}</div>
                  <div style={{width: '35%'}} className="small-9 medium-9 columns top_space1">
                    <SelectLine value={this.state.strength_lower_body_reps}
                          name="strength_lower_body_reps" onChange={this.handleChange}
                          menuItems={repsMap} wide noClass noHorizontalPadding
                          disabled={this.state.completed && !this.state.edit}/>
                  </div>
                  <div className="small-12 columns sep_line4">&nbsp;</div>
                  <div className="small-12 medium-3 columns title1 top_space1">{(lang=='en' && 'Band') || lang=='es' && 'Banda'}</div>
                  <div className="small-12 medium-9 columns top_space1">
                  {frm_yes_no.map(each =>
                    <div className="small-6 columns float_left" key={'slbb'+each.value}>
                      <input type="radio" name="strength_lower_body_band" value={each.value}
                      disabled={(this.state.completed && 'disabled') && (!this.state.edit && 'disabled')}
                      checked={this.state.strength_lower_body_band == each.value && 'checked'}
                      onChange={(e) => this.handleChange(e)}/>
                      <span>{(each.label == 'Yes' && lang=='es' && 'Si')}{((each.label == 'No' || lang=='en') && each.label)}</span>
                    </div>)}
                  </div>
              </div>}
              </div>
            </div>
            </div>
            <div className="small-12 medium-4 columns">
              <img src={(lang=='en' && SampleImage) || (lang=='es' && SampleImageES)} />
            </div>
          </div>


          <div className="small-12 columns sep_line3">&nbsp;</div>

          <div className="small-12 columns title2">{(lang=='en' && 'How much did you exert yourself during session?') || lang=='es' && '¿Cuánto te esforzaste durante esta sesión?'}</div>
          <div className="small-12 columns top_space1 radio_scale1">
            {choices.map(each =>
            <div className="small-12 medium-1 columns float_left label_scale1" key={'eles'+each.value}>
              <input type="radio" name="exertion_scale" value={each.value}
              disabled={(this.state.completed && 'disabled') && (!this.state.edit && 'disabled')}
              checked={this.state.exertion_scale == each.value && 'checked'}
              onChange={(e) => this.handleChange(e)}/>
              <span >{each.label}</span>
            </div>)}
          </div>

          <div className="small-12 columns sep_line3">&nbsp;</div>

          <div className="row">
            <div className="small-6 columns back_button1 float_left">
              <a href="/member/dashboard">{(lang=='en' && 'Back') || (lang=='es' && 'Volver')}</a>
            </div>
            {!this.state.completed &&
            <div className="small-6 columns text_align_right1 top_space1">
              <Button variant="contained" color="primary"
                 onClick={this.handleSubmit} type="submit">
                  {(lang=='en' && 'Submit') || (lang=='es' && 'Enviar')}
              </Button>
            </div>}
            {this.state.completed && !this.state.edit &&
            <div className="small-6 columns text_align_right1 top_space1">
              <Button variant="contained" color="primary"
                 onClick={this.handleEdit} type="submit">
                  Edit
              </Button>
            </div>}
            {this.state.completed && this.state.edit &&
            <div className="small-6 columns text_align_right1 top_space1">
              <Button variant="contained" color="primary"
                 onClick={this.handleSave} type="submit">
                  Save
              </Button>
            </div>}
          </div>

        </div>

      {this.state.shared_exercise_log.length > 0 &&
      <div className="small-12 columns box_assm1 more_padding1 top_space1 shared_with_me">
        <div className="small-12 columns title_share1">
          Shared with me
        </div>
        <div className="small-12 columns">
          {this.state.shared_exercise_log.map(each =>
            <>
            <div className="small-12 columns sep_line3">&nbsp;</div>
            <div className="row">
              <div className="small-12 columns mod_title2">
                <FaceIcon /> <span className="space1">{each.name}</span>
              </div>
            </div>
            <div className="row">
              <div className="small-4 columns mod_title1">Stretching / Flexibility:</div>
              <div className="small-8 columns">{each.flexibility_mins} minutes</div>
            </div>
            <div className="row">
              <div className="small-4 columns mod_title1">Aerobic:</div>
              <div className="small-8 columns">
                {this.findLabelByValue(this.state.aerobic_activity_values, each.aerobic_activity)}
                {each.aerobic_activity_other && '['+each.aerobic_activity_other+']'}
                &nbsp;{each.aerobic_mins && '/ '+each.aerobic_mins} minutes
                {each.aerobic_intensity > 0 && ' / '}
                {this.findLabelByValue(this.state.exercise_log_intensity, each.aerobic_intensity)}
              </div>
            </div>
            <div className="row">
              <div className="small-4 columns mod_title1">
                Strength:
              </div>
              <div className="small-8 columns">
                {each.strength_upper_body == 1 &&
                <>
                  Upper Body:  Sets {each.strength_upper_body_sets},
                               Reps {each.strength_upper_body_reps},
                               Band {(each.strength_upper_body_band == 1 && 'Yes')
                                    || (each.strength_upper_body_band == 2 && 'No')}
                </>}
                {each.strength_upper_body == 1 && each.strength_lower_body == 1 && <br />}
                {each.strength_lower_body == 1 &&
                <>
                  Lower Body:  Sets {each.strength_lower_body_sets},
                               Reps {each.strength_lower_body_reps},
                               Band {(each.strength_lower_body_band == 1 && 'Yes')
                                    || (each.strength_lower_body_band == 2 && 'No')}
                </>}
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <b>How much did you exert yourself during session?</b> &nbsp;
                {this.findLabelByValue2(choices, each.exertion_scale)}
              </div>
            </div>
            </>
          )}
        </div>
      </div>}

      <div className="warning_box width_100p no_margin_left" id="warning_box">
      <div className="row">
        <div className="small-12 columns">
          <ShareExerciseLog />
        </div>
      </div>
    </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ExerciseLog)
