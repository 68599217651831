import React, { Component } from 'react'
import Image from '../../images/banner_about2.jpg'

class Deletion extends Component {
  render() {
    return (
      <div className="">
        <div className="wrapper row">
          <div className="small-12 columns">
            <div className="title">
              <p>&nbsp;</p>
              Delete testing information successfully
              <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Deletion
